<template>
  <div class="coin">
    <navbarTools
      :leftArrow="true"
      :fixed="true"
      :rightText="rightText"
      :rightFn="rightFn"
    ></navbarTools>
    <div class="box">
      <div class="coinBox">
        <div class="coinInfo">
          <div class="title">余额</div>
          <div class="num">{{ account?.availableCount }}</div>
        </div>
        <div class="coin-count">
          <div class="income">
            <div>总收入</div>
            <div>{{ account?.totalAddCount }}</div>
          </div>
          <div class="pay">
            <div>总支出</div>
            <div>{{ account?.totalCutCount }}</div>
          </div>
        </div>
      </div>
      <div class="billBox">
        <div>
          <span @click="DateFn">
            <span class="date">{{ dateTxt }}</span>
            <span>
              <van-icon name="arrow-down" />
            </span>
          </span>
          <span
            >支出:{{ otherData?.payCoin }}工钱币/收入:{{
              otherData?.incomeCoin
            }}工钱币</span
          >
        </div>
        <div class="types">
          <span :class="{ all: pages.operation == 'all' }" @click="changeTypes('all')"
            >全部</span
          >
          <span :class="{ pay: pages.operation == 'pay' }" @click="changeTypes('pay')"
            >支出</span
          >
          <span
            :class="{ income: pages.operation == 'income' }"
            @click="changeTypes('income')"
            >收入</span
          >
        </div>
      </div>
    </div>
    <div class="coinList" v-if="nullList">
      <van-list
        :immediate-check="false"
        v-model:loading="loading"
        :finished="finished"
        @load="onloadFn"
      >
        <van-cell :border="false" v-for="(item, index) in coinSerialList" :key="index">
          <template #icon>
            <img :src="item.equityIcon" />
          </template>
          <template #title
            ><span class="fs14 name">{{ item.equityBatchName }}</span></template
          >
          <template #right-icon
            ><span class="fs16 c-333 money">{{
              item.operateTypeTag == "-" ? "-" + item.operateMoney : item.operateMoney
            }}</span></template
          >
          <template #label
            ><span class="content">{{ item.equityName }}</span>
            <span class="content pl10">{{ item.createTime }}</span></template
          >
        </van-cell>
      </van-list>
    </div>
    <div v-else class="no_record">
      <img :src="OSS_URL + '/null.png'" />
    </div>
    <van-popup
      v-model:show="show"
      position="bottom"
      :style="{ height: '40%' }"
      class="datePopu"
    >
      <div class="types">
        <span
          class="fs16"
          :class="{ active: pages.dateType == 'year' }"
          @click="changeDateFn('year')"
          >按年选择</span
        >
        <span
          class="fs16"
          :class="{ active: pages.dateType == 'month' }"
          @click="changeDateFn('month')"
          >按月选择</span
        >
      </div>
      <div class="datetimePicker">
        <van-picker
          :columns="columns"
          @change="changePickerFn"
          @cancel="show = false"
          ref="picker"
          item-height="30px"
          @confirm="confirmDateFn"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
import navbarTools from "@/components/navbarTools";
import { defineComponent, reactive, toRefs, ref } from "vue";
import { coinAccount, serialDataTime, coinSerial } from "@/api/coin";
import { useRouter } from "vue-router";
import { filterDate } from "@/utils/date";
export default defineComponent({
  components: {
    navbarTools,
  },
  setup() {
    let picker = ref(null);
    const $router = useRouter();
    const state = reactive({
      rightText: "规则说明",
      OSS_URL: process.env.VUE_APP_OSS_URL,
      show: false,
      account: null,
      dateObj: null,
      dateTxt: null,
      pages: {
        pageSize: 10,
        page: 1,
        dateType: "year",
        operation: "all",
        searchDate: "",
        tenantId: localStorage.getItem("tenantId"),
        tenantUserId: localStorage.getItem("tenantUserId"),
      },
      columns: null,
      dateArr: null,
      coinSerialList: [],
      otherData: null,
      loading: false,
      finished: false,
      scrollPickerValue: null,
      nullList: true,
    });
    const DateFn = () => {
      state.show = true;
    };
    const coinAccountFn = async () => {
      let rs = await coinAccount({
        tenantId: localStorage.getItem("tenantId"),
        tenantUserId: localStorage.getItem("tenantUserId"),
      });
      if (rs.code === 0) {
        state.account = rs.data;
      }
    };
    const serialDataTimeFn = async () => {
      let rs = await serialDataTime({
        tenantId: localStorage.getItem("tenantId"),
        tenantUserId: localStorage.getItem("tenantUserId"),
      });
      if (rs.code === 0) {
        state.dateObj = rs.data;
        state.dateArr = filterDate("2018-08", rs.data.currentDate);
        state.columns = Object.keys(state.dateArr).reverse();
        state.pages.searchDate = state.columns[0];
        state.dateTxt = state.columns[0];
        coinSerialFn();
      }
    };
    const changeDateFn = (dateType) => {
      if (dateType != state.pages.dateType) {
        let year = state.dateObj.currentDate.split("-")[0].toString();
        state.pages.dateType = dateType;
        if (dateType == "year") {
          state.columns = Object.keys(state.dateArr).reverse();
        } else if (dateType == "month") {
          state.columns = [
            {
              values: Object.keys(state.dateArr).reverse(),
              className: "year",
            },
            {
              values: state.dateArr[year],
              className: "month",
              defaultIndex: 0,
            },
          ];
        }
      }
    };
    const coinSerialFn = async () => {
      let rs = await coinSerial(state.pages);
      if (rs.code === 0) {
        state.coinSerialList = [...state.coinSerialList, ...rs.data.list];
        state.otherData = rs.data.otherData;
        if (state.coinSerialList.length > 0) {
          state.nullList = true;
        } else {
          state.nullList = false;
        }
        if (state.pages.page >= rs.data.totalpage) {
          state.finished = true;
        } else {
          state.loading = false;
        }
      }
    };
    const confirmDateFn = (e) => {
      state.show = false;
      state.pages.page = 1;
      state.coinSerialList = [];
      state.loading = false;
      state.finished = false;
      if (state.pages.dateType == "year") {
        state.dateTxt = e;
        state.pages.searchDate = e;
      } else {
        state.dateTxt = `${e[0]}-${e[1]}`;
        state.pages.searchDate = `${e[0]}-${e[1]}`;
      }

      coinSerialFn();
    };
    const changeTypes = (types) => {
      if (types != state.pages.operation) {
        state.pages.operation = types;
        state.pages.page = 1;
        state.coinSerialList = [];
        state.loading = false;
        state.finished = false;
        coinSerialFn();
      }
    };
    const changePickerFn = (e) => {
      if (state.pages.dateType == "month") {
        picker.value.setColumnValues(1, state.dateArr[e[0]]);
      }
    };
    const rightFn = () => {
      $router.push({
        path: "/coin/rules",
      });
    };
    const onloadFn = () => {
      setTimeout(() => {
        state.pages.page = state.pages.page + 1;
        coinSerialFn();
      }, 1000);
    };
    coinAccountFn();
    serialDataTimeFn();
    return {
      DateFn,
      rightFn,
      changeDateFn,
      changePickerFn,
      changeTypes,
      picker,
      coinSerialFn,
      onloadFn,
      confirmDateFn,
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss" scoped>
.coin {
  background: #fff;
  min-height: 100vh;
  ::v-deep(.navbar) {
    width: 375px;
    .van-nav-bar {
      .van-nav-bar__left .van-icon {
        font-size: 24px;
        color: #000;
      }
      .van-nav-bar__title {
        font-size: 18px;
        font-weight: 600;
      }
      .van-nav-bar__right .van-nav-bar__text {
        color: #333;
      }
    }
  }
  .box {
    position: sticky;
    top: 0;
    z-index: 99;
    background: #fff;
    .coinBox {
      width: 345px;
      height: 169px;
      background: linear-gradient(148deg, #ffa357 0%, #fc7301 100%);
      border-radius: 8px;
      position: relative;
      top: 56px;
      left: 15px;
      .coinInfo {
        height: 117px;
        width: 100%;
        text-align: center;
        color: #fff;
        .title {
          font-size: 16px;
          padding-top: 20px;
        }
        .num {
          font-size: 26px;
          padding-top: 15px;
        }
      }
      .coin-count {
        background: #ff973f;
        height: 52px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        color: #fff;
        font-size: 14px;
        .income,
        .pay {
          text-align: center;
          flex: 1;
        }
        .income {
          border-right: 1px solid #fff;
        }
      }
    }
    .billBox {
      background: #fff;
      width: 345px;
      position: relative;
      top: 65px;
      left: 15px;
      font-size: 12px;
      color: #999;
      border-bottom: 1px solid #ededed;
      padding-bottom: 10px;
      .date {
        font-size: 18px;
        color: #333;
      }
      .van-icon {
        color: #333;
        vertical-align: middle;
        padding: 0 5px;
      }
      .types {
        font-size: #666;
        font-size: 16px;
        width: 150px;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        .all {
          color: #ff844e;
          font-weight: 600;
        }
        .pay {
          color: #36ae1d;
          font-weight: 600;
        }
        .income {
          color: #ff8c59;
          font-weight: 600;
        }
      }
    }
  }

  .coinList {
    position: relative;
    top: 75px;
    ::v-deep(.van-cell__title) {
      line-height: 20px;
    }
    .name {
      font-size: 14px;
      color: #333;
      font-weight: 500;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 250px;
      display: inline-block;
      font-weight: bold;
    }
    .content {
      font-size: 14px;
      color: #333;
    }
    .money {
      color: #333333;
      font-size: 16px;
    }
    ::v-deep(.van-cell--borderless) {
      align-items: center;
      img {
        width: 34px;
        height: 31px;
      }
      .van-cell__title {
        font-size: 16px;
        font-weight: 600;
        color: #333;
        padding-left: 10px;
      }
      .van-cell__label {
        font-size: 14px;
        color: #333;
        font-weight: 400;
      }
    }
  }
  .datePopu {
    position: relative;
    .types,
    .dateBtn {
      display: flex;
      justify-content: space-between;
    }
    .types {
      justify-content: center;
      position: absolute;
      z-index: 999;
      top: 45px;
      background: #fff;
      span {
        display: inline-block;
        margin: 0px 40px;
        text-align: center;
        height: 30px;
        line-height: 30px;
        border-radius: 8px;
        width: 100px;
      }
      .active {
        background: #ff844e;
        color: #fff;
      }
    }
    .datetimePicker {
      .van-picker {
        height: 200px;
        ::v-deep(.van-picker__toolbar) {
          position: relative;
          button {
            font-size: 16px;
          }
          .van-picker__cancel {
            color: #666;
          }
        }
      }
    }
  }
  .no_record {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 174px;
      margin-top: 120px;
    }
  }
}
</style>
