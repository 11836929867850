export function filterDate(start, end) {
    let pickerList = {};
    let year = start.split('-')[0];
    let month = start.split('-')[1];
    let monthArr = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    if (year == end.split('-')[0]) {
        pickerList[year] = monthArr.slice(monthArr.findIndex(item => item == month), monthArr.findIndex(item => item == end.split('-')[1]) + 1);
    } else {
        pickerList[year] = monthArr.slice(monthArr.findIndex(item => item == month), monthArr.length);
        for (let i = parseInt(year) + 1; i < parseInt(end.split('-')[0]); i++) {
            pickerList[i] = monthArr;
        }
        pickerList[end.split('-')[0]] = monthArr.slice(0, monthArr.findIndex(item => item == end.split('-')[1]) + 1);
    }
    return pickerList;
}

export function dateFn() {
    let Stamp = new Date();
    let year = Stamp.getFullYear(); //获取完整的年份(4位,1970-????)
    let month = Stamp.getMonth() + 1; //获取当前月份(0-11,0代表1月)
    let day = Stamp.getDate();
    let mvar = "";
    let dvar = "";
    if (month < 10) {
        mvar = "0" + month;
    } else {
        mvar = month + "";
    }

    if (day < 10) {
        dvar = "0" + day;
    } else {
        dvar = day + "";
    }
    return `${year}-${mvar}-${dvar}`
}